import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import logo_jp2 from './logo_noBg_v1_3.jp2';
import logo_jpg from './logo_noBg_v1_3.png';
import {Navbar, Nav, Container} from 'react-bootstrap';



class Header extends Component {

  render() {


    return (

      <header>
        <Navbar collapseOnSelect className="mainHeader" expand="sm" variant="dark" fixed="top">
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand href="#home">
                    <picture>
                        <source srcSet={logo_jp2} type="image/jp2" />
                        <source srcSet={logo_jpg} type="image/jpeg" />
                        <img src={logo_jpg} className="logo" alt="remati logo" />
                    </picture>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav className="ml-auto">
                <LinkContainer to="/" exact={true}>
                  <Nav.Link eventKey={1}>
                    Home
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/imprint" exact={true}>
                  <Nav.Link eventKey={2}>
                    Impressum
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/dsgvo" exact={true}>
                  <Nav.Link eventKey={3}>
                    DSGVO
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>


      </header>
    );
  }
}

export default Header;
