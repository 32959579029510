import React from 'react';

class NavDot extends React.Component {
  constructor(props) {
    super(props);
    this.goToCard = this.goToCard.bind(this);
    this.idList = []
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    for (var i = 0; i < this.idList.length; i++) {
      var element = document.getElementById(this.idList[i]);
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.top <= (window.innerHeight/2)) {
        element = document.getElementById(this.idList[i].replace("navtitle-", "navdot-")).getElementsByClassName("navdot-dot")[0];
        element.classList.add("active-dot");
      } else if (rect.bottom <= window.innerHeight && rect.bottom >= (window.innerHeight/2)){
        element = document.getElementById(this.idList[i].replace("navtitle-", "navdot-")).getElementsByClassName("navdot-dot")[0];
        element.classList.add("active-dot");
      } else {
        var subelement = document.getElementById(this.idList[i].replace("navtitle-", "navdot-")).getElementsByClassName("navdot-dot active-dot")[0];
        if (subelement != null) {
          subelement.classList.remove("active-dot");
        }
      }
    }
  }

  goToCard(title) {
    var element = document.getElementById("navtitle-" + title);
    element.scrollIntoView();
  }



  renderDot = () =>  {
    this.idList.push("navtitle-" + this.props.navtitle);
    var built_navdot = (
      <div className='nav-dot' id={"navdot-" + this.props.navtitle}>
        <div
          className={'navdot-dot ' + this.props.className}
          onClick={() => this.goToCard(this.props.navtitle)}
        >
        </div>
        <p className='navdot-title' onClick={() => this.goToCard(this.props.navtitle)}>
          {this.props.navdesc}
        </p>
        </div>

    );
    return built_navdot;
  }



  render() {
    return(
      <div>
        {this.renderDot()}
        </div>
    );
  }
}

export default NavDot;
