import React, { Component } from 'react';


class Impressum extends Component {
  render() {
    return (
      <div className='container-fluid imprint'>
        <center>
        <h1> Impressum </h1>
        </center>
        <br/>
        <p>
        Mauersberger Industrievertretungen e.K.
        <br/>
        Inhaber: Dieter Mrochen
        <br/>
        Siedlerstr. 8
        <br/>
        90574 Rosstal
        <br/>
        <br/>

        info@mauersberger-vertrieb.de
        <br/>
        <br/>

        Tel:  09127 90 38 66
        <br/>
        Fax:  09127 90 38 67
        </p>

        <h3>Diese Seite ist erstellt worden von:</h3>
        <p>
        Mauersberger Industrievertretungen e.K.
        <br/>
        Jan Mrochen
        <br/>
        Siedlerstr. 8
        <br/>
        90574 Rosstal
        </p>

        <h3>Und basiert auf:</h3>
        <p>
        React.js
        </p>
        <h3>Haftungsausschluss</h3>
        <br/>

        <h4>Inhalte</h4>
        <p>Der Inhalt dieser Seiten wurde sorgfältig bearbeitet und überprüft. Die Firma Mauersberger übernimmt jedoch keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen die Firma Mauersberger, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen oder durch fehlerhafte und unvollständige Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens der Firma Mauersberger kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Die Firma Mauersberger behält sich ausdrücklich das Recht vor, Teile der Seite oder das gesamte Angebot ohne vorherige Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung einzustellen.
        </p>
        <h4>Links</h4>
        <p>Sofern die Firma Mauersberger direkt oder indirekt auf fremde Internetseiten verweist ("Links"), haftet die Firma Mauersberger, wenn sie von den Inhalten genaue Kenntnis hat und es ihr technisch möglich und zumutbar ist, die Nutzung im Fall rechtswidriger Inhalte zu verhindern. Die Firma Mauersberger erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung die verlinkten Seiten keine illegalen Inhalte enthalten haben. Die Firma Mauersberger hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung der verlinkten Seiten. Die Firma Mauersberger distanziert sich daher hiermit ausdrücklich von allen inhaltlichen Änderungen, die nach der Linkssetzung auf den verlinkten Seiten vorgenommen werden. Die Firma Mauersberger ist nicht verantwortlich für den Inhalt, die Verfügbarkeit, die Richtigkeit und die Genauigkeit der verlinkten Seiten, deren Angebote, Links oder Werbeanzeigen. Die Firma Mauersberger haftet nicht für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere nicht für Schäden, die durch Nutzung oder Nichtnutzung der auf den verlinkten Seiten angebotenen Informationen entstehen.
        </p>
        <h4>Urheberrecht</h4>
        <p>Die Firma Mauersberger ist bestrebt, in allen Publikationen geltende Urheberrechte zu beachten. Sollte es trotzdem zu einer Urheberrechtsverletzung kommen, wird die Firma Mauersberger das entsprechende Objekt nach Benachrichtigung aus seiner Publikation entfernen bzw. mit dem entsprechenden Urheberrecht kenntlich machen. Alle innerhalb des Internetangebots genannten und gegebenenfalls durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind. Das Urheberecht für die eigenen Inhalte der Firma Mauersberger auf den Domains www.mauersberger-vertrieb.de steht allein der Firma Mauersberger zu. Eine Vervielfältigung solcher Grafiken, Sounds oder Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung der Firma Mauersberger nicht gestattet.
        </p>
        <h4>Rechtswirksamkeit</h4>
        <p>Dieser Haftungsausschluss ist als Teil des Internetangebots zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokuments in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.
        </p>
      </div>
    );
  }
}

export default Impressum;
