import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
}from 'react-router-dom'


import Header from './components/headerComponents/header';
import Footer from './components/footerComponents/footer';
import Homepage from './components/pages/homePage';
import Impressum from './components/pages/impressum';
import Dsgvo from './components/pages/dsgvo';


function App() {



  return (
    <Router onUpdate={() => window.scrollTo(0, 0)} >
    <div className="App">
      <Header/>
      <Switch>
        {/* Routing */}
        <Route exact path='/' component={Homepage} />
        <Route exact path='/imprint' component={Impressum} />
        <Route exact path='/dsgvo' component={Dsgvo} />
        <Route component={Homepage} />
      </Switch>
      <Footer/>
    </div>
  </Router>
  );
}

export default App;
