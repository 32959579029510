import React, { Component } from 'react';


class Footer extends Component {
  render() {
    return (
      <footer>
          <p>Copyright &copy; remati 2021. All rights reserved.</p>
      </footer>
    );
  }
}

export default Footer;
