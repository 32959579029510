import React, { Component } from 'react';

// Images
import welcome_video from './images/remati-lq.mp4';
import imageAuto_JP2 from './images/automation.jp2';
import imageAuto_jpg from './images/automation.jpg';
import imageHWA_JP2 from './images/metal.jp2';
import imageHWA_jpg from './images/metal.jpg';


import NavDot from './navIndicator';

class Homepage extends Component {

    render() {

    return (
      <div className="mainCont">
          <div className='card' id='navtitle-main'>
            <video className="video_main" autoPlay playsInline muted >
              <source src={welcome_video} type="video/mp4"/>
            </video>
            <div className="video_overlay">
              <h1 className="title_video">WARUM NICHT EINFACH DIE TECHNIK VON MORGEN ENTDECKEN? <br/> <br/> Industrie 4.0 nicht nur auf dem Papier!</h1>
            </div>
          </div>
          <div className='card' id='navtitle-whoWeAre'>
              <picture>
                  <source srcSet={imageAuto_JP2} type="image/jp2" />
                  <source srcSet={imageAuto_jpg} type="image/jpeg" />
                  <img src={imageAuto_jpg} className="bgImage" alt="Remati Automation" />
              </picture>
            <div className="video_overlay">
              <div className="desc_overlay">
              <p><b><span className="initials">remati</span></b> steht für <b><span className="initials">re</span></b>nt <b><span className="initials">ma</span></b>chine <b><span className="initials">ti</span></b>me und bietet Ihnen die Möglichkeit
                  mit der neuesten am Markt erhältlichen Technik, integriert in eine
                  Roboterautomatisierung, zu arbeiten.
                  <br/><br/>
                  Dadurch können Sie die neueste Technik auf Herz und Nieren testen
                  oder Ihre Produktionskapazität durch Fertigung an unserm Standort erweitern.
                  <br/><br/>
                  Und das alles ohne hohe Investitionen zu tätigen.
                </p>
              </div>
            </div>
          </div>
          <div className='card' id='navtitle-misc'>
              <picture>
                  <source srcSet={imageHWA_JP2} type="image/jp2" />
                  <source srcSet={imageHWA_jpg} type="image/jpeg" />
                  <img src={imageHWA_jpg} className="bgImage" alt="Remati Metal Background" />
              </picture>

              <div className="img_overlay">
                <div className="key-facts">
                  <h3>Remote arbeiten</h3>
                  <p>Steuern Sie die Produktionsanlage direkt von Ihrem Arbeitsplatz oder einfach zu Hause aus dem Home Office.</p>
                  <h3>24/7 Produktion</h3>
                  <p>Unser System kennt kein Wochenende oder Feiertage. Sie produzieren selbst dann, wenn Sie feiern oder einen guten Krimi im Fernsehen anschauen.</p>
                  <h3>Live Status jeder Komponente weltweit</h3>
                  <p>Unser System kann von jedem erdenklichen Ort auf der Welt gesteuert werden, solange Sie Internet haben.</p>
                </div>


              </div>
          </div>
          <div className="navIndicator">
            <div className="navIndicator-container">
              <NavDot className="active-dot" navtitle="main" navdesc="Willkommen"/>
              <NavDot navtitle="whoWeAre" navdesc="Über uns"/>
              <NavDot navtitle="misc" navdesc="Sonstiges"/>
            </div>
          </div>
      </div>
    );
  }
}

export default Homepage;
